"use client";

import { HomeDocument } from "@/gql/graphql";
import { Program } from "@/services/types";
import eventTracker from "@/utils/eventTracker";
import { extractButtonProps, extractImage } from "@/utils/strapiTojs";
import { useGraphQL } from "@/utils/useGraphQL";
import { useQuery } from "@tanstack/react-query";
import Head from "next/head";
import { usePathname } from "next/navigation";
import { useContext, useEffect } from "react";
import SectionAdvantages from "./SectionAdvantages";
import SectionAlumni from "./SectionAlumni";
import SectionCtaCard from "./SectionCtaCard";
import SectionHero from "./SectionHero";
import SectionInfluencers from "./SectionInfluencers";
import SectionInvestors from "./SectionInvestors";
import SectionLearners from "./SectionLearners";
import { SectionMarquee } from "./SectionMarquee";
import SectionPrograms from "./SectionPrograms";
import SectionTabs from "./SectionTabs";
import SectionTestimonials from "./SectionTestimonials";
import SectionUpskill from "./SectionUpskill";
import { fetchDiscoveryData } from "@/services/external";
import * as pixel from "../../../utils/fpixel";
import { AppContext } from "@/contexts/appcontext";
import HomePageSkeleton from "./skeleton";
export default function HomePage() {
  const {
    pixelLoaded
  } = useContext<any>(AppContext);
  const {
    data,
    isLoading
  } = useGraphQL(HomeDocument);
  const pathname = usePathname();
  const {
    data: learnerData,
    isLoading: isLearnerDataLoading
  } = useQuery<Program[]>({
    queryKey: ["learner-api"],
    queryFn: () => fetchDiscoveryData()
  });
  const testimonials = data?.testimonials?.data;
  const content = data?.home?.data?.attributes?.content;
  const content1 = testimonials?.filter((item: any) => item?.attributes?.prevDesignation !== null);
  const schema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: "Become Top 1% in the AI-First World",
    description: "Whether it is Product, Growth, Design, Business, Tech or Data, GrowthSchool is the place to learn from top experts in the field to become the Top 1%",
    provider: {
      "@type": "Organization",
      name: "GrowthSchool"
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined" && typeof window.gtag === "function") {
      window.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
        event_label: "Home Page Viewed",
        page_path: `${process.env.NEXT_PUBLIC_APP_URL}${pathname}`
      });
      window.webengage.onReady(() => {
        window.webengage.track("Page Viewed", {
          page_url: `${process.env.NEXT_PUBLIC_APP_URL}${pathname}`
        });
      });
    }
    eventTracker.homePagePageViewed({
      pageName: "Home page"
    });
  }, []);
  useEffect(() => {
    if (!pixelLoaded) return;
    pixel.pageview();
  }, [pixelLoaded]);
  if (isLoading || isLearnerDataLoading) {
    return <HomePageSkeleton />;
  }
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="HomePage.tsx">
        <link href="/favicon-new.svg" rel="icon" type="image/x-icon" />
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_APP_URL}${pathname}`} />
        <link rel="preconnect" href="https://s3.ap-south-1.amazonaws.com/assets.growthschool.io" crossOrigin="anonymous" />
        <link rel="dns-prefetch" href="https://s3.ap-south-1.amazonaws.com/assets.growthschool.io" />
        <meta property="og:image" content="https://assets-global.website-files.com/624194472db3153002097068/624ace96c9f9ab0aed097ba6_GS%20Logo%20(5)%201.svg" data-sentry-element="meta" data-sentry-source-file="HomePage.tsx" />
      </Head>

      <section>
        <script id="schema-script" type="application/ld+json" dangerouslySetInnerHTML={{
        __html: `${JSON.stringify(schema)}`
      }} />
      </section>

      {typeof content !== "undefined" && content?.map((item, index) => {
      switch (item?.__typename) {
        case "ComponentHomeFirstBlock":
          return <SectionHero key={item?.Title} title={item.Title ?? ""} description={item.Description ?? ""} button={extractButtonProps(item.CTA)} textScroll={item.TextScroll?.map(item => item?.Text ?? "") ?? []} />;
        case "ComponentHomeSecondBlock":
          const students = item.Students?.map(student => ({
            studentImage: extractImage(student?.StudentImage),
            companyImage: extractImage(student?.CompanyImage)
          }));
          const trustCards = item.TrustCards?.map(trustCard => ({
            smallText: trustCard?.SmallText ?? "",
            bigText: trustCard?.BigText ?? "",
            logo: extractImage(trustCard?.Image)
          }));
          return <>
                <SectionMarquee key={item?.StudentCount} studentCount={item.StudentCount ?? 0} studentDescription={item.StudentDescription ?? ""} studentText={item.StudentText ?? ""} students={students ?? []} trustCards={trustCards ?? []} />
                <SectionPrograms key={`${item?.StudentCount}-Programs`} learnerData={learnerData ?? []} />
              </>;
        case "ComponentHomeGlimpse":
          return <SectionCtaCard key={item?.Title} title={item.Title ?? ""} quizLink={extractButtonProps(item.QuizLink)} pathName={pathname} />;
        case "ComponentHomeAdvantage":
          const sliders = item.Slider?.map(slider => ({
            smallText: slider?.SmallText ?? "",
            bigText: slider?.BigText ?? "",
            image: extractImage(slider?.Image)
          }));
          return <SectionAdvantages key={`${index}`} bigText={item.BigText ?? ""} smallText={item.SmallText ?? ""} slider={sliders ?? []} />;
        case "ComponentHomeSuccessStories":
          const successStories = content1?.map(story => ({
            studentImage: extractImage(story?.attributes?.userImage),
            imageURL: story?.attributes?.userImageUrl ?? "",
            studentName: story?.attributes?.userName ?? "",
            fromText: story?.attributes?.designation ?? "",
            fromCompany: story?.attributes?.prevCompany ?? "",
            toText: story?.attributes?.prevDesignation ?? "",
            toCompany: story?.attributes?.company ?? ""
          }));
          return <SectionLearners key={item?.BigText} bigText={item.BigText ?? ""} smallText={item.SmallText ?? ""} successCards={successStories ?? []} />;
        case "ComponentHomeTopBrands":
          const logos = item.BrandLogos?.map(logo => ({
            image: extractImage(logo?.Image)
          }));
          return <SectionAlumni key={`${index}`} logos={logos ?? []} title={item.Title ?? ""} />;
        case "ComponentHomeFeedBackDetail":
          const feedBackCards = item.FeedBackCards?.map(card => ({
            description: card?.Description ?? "",
            name: card?.Name ?? "",
            designation: card?.Designation ?? "",
            image: extractImage(card?.Image),
            socialIcon: extractImage(card?.SocialIcon),
            socialLink: card?.SocialLink ?? "",
            video: extractImage(card?.Video),
            isTextTestimonial: card?.IsTextTestimonial ?? true
          }));
          return <SectionTestimonials key={`${index}`} bigText={item.BigText ?? ""} smallText={item.SmallText ?? ""} feedBackCards={feedBackCards ?? []} />;
        case "ComponentHomeSkillUpDetail":
          const whyUs = item.WhyUs?.map(item => ({
            title: item?.Title ?? "",
            description: item?.Description ?? "",
            icon: extractImage(item?.Icon)
          }));
          const collaborationBrand = item.CollaborationBrand?.map(item => ({
            image: extractImage(item?.Image)
          }));
          return <SectionUpskill key={`${index}`} caption={item.Caption ?? ""} title={item.Title ?? ""} subTitle={item.SubTitle ?? ""} buttonText={item.ButtonText ?? ""} buttonLink={item.ButtonLink ?? ""} whyUsTitle={item.WhyUsTitle ?? ""} whyUs={whyUs ?? []} brandCollabTitle={item.BrandCollabTitle ?? ""} collaborationBrand={collaborationBrand ?? []} />;
        case "ComponentHomeOurCommunity":
          const categories = item.Categories?.map(category => {
            const communityDetails = category?.community_details?.data;
            return {
              id: category?.id ?? "",
              categoryName: category?.CategoryName ?? "",
              communityDetails: communityDetails?.map(item => ({
                image: extractImage(item.attributes?.CommunityDetail?.Image),
                caption: item.attributes?.CommunityDetail?.Caption ?? "",
                location: item.attributes?.CommunityDetail?.Location ?? ""
              })) ?? [],
              bigText: category?.BigText ?? "",
              smallText: category?.SmallText ?? ""
            };
          });
          return <SectionTabs key={`${index}`} caption={item.Caption ?? ""} title={item.Title ?? ""} description={item.Description ?? ""} buttonText={item.ButtonText ?? ""} buttonLink={item.ButtonLink ?? ""} categories={categories ?? []} />;
        case "ComponentHomeRecommendedCard":
          const cards = item.Crads?.map((card, index) => ({
            id: card?.id ?? "",
            idx: index + 1,
            description: card?.Description ?? "",
            name: card?.Name ?? "",
            designation: card?.Designation ?? "",
            image: extractImage(card?.Image)
          }));
          return <SectionInfluencers key={item?.BigText} bigText={item.BigText ?? ""} smallText={item.SmallText ?? ""} cards={cards ?? []} />;
        case "ComponentHomeBehindOurGrowth":
          const growthTeamImages = item.GrowthTeamImages?.map(image => ({
            image: extractImage(image?.image)
          }));
          const companyLogos = item.CompanyLogos?.data?.map(logo => ({
            logo: extractImage({
              data: logo
            })
          }));
          return <SectionInvestors key={`${index}`} bigText={item.BigText ?? ""} smallText={item.SmallText ?? ""} subTitle={item.SubTitle ?? ""} companyLogos={companyLogos ?? []} growthTeamImages={growthTeamImages ?? []} />;
      }
    })}
    </>;
}